import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useCalendarList() {
    const calendarDateListTable = ref([]);

    const calendarDateTableColumns = [
        { text: i18n.t('Date'), value: 'date', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('ExceptionType'), value: 'exception_type', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalCalendarDateListTable = calendarDateListTable.length;

    const fetchCalendarDateList = () => {
        store
            .dispatch('app-routes/fetchCalendarDateList', {
                //  ...(searchQuery.value && { q: searchQuery.value }),
                // ...(countryFilter.value && { country: countryFilter.value }),
            })
            .then((response) => {
                console.log(response);
                // const { filteredData, total, parkTotal } = response.data;

                calendarDateListTable.value = response.data.data;
                totalCalendarDateListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return {
        calendarDateListTable,
        calendarDateTableColumns,
        totalCalendarDateListTable,
        fetchCalendarDateList,
    };
}
