<template>
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
        <v-container>
            <v-tabs center-active v-model="activeTab" @change="getTripsSelectedTab">
                <v-tab>{{ $t('TripsList') }}</v-tab>
                <v-tab> {{ $t('SchedulesList') }}</v-tab>
                <v-tab>{{ $t('Calendar') }}</v-tab>

                <v-tab-item>
                    <v-container class="mt-4">
                        <div id="routes-list" class="mt-8">
                            <div class="d-flex">
                                <!-- search -->
                                <v-text-field
                                    expanded
                                    :placeholder="$t('Search')"
                                    outlined
                                    hide-details
                                    dense
                                    :prepend-inner-icon="icons.mdiMagnify"
                                    class="user-search me-3 mb-4"
                                    @change="searchTrips()"
                                    v-model="searchTripsQuery">
                                </v-text-field>

                                <v-btn @click.stop="getTripFilter" outlined color="grey-light">
                                    <v-icon color="grey">
                                        {{ icons.mdiFilterVariant }}
                                    </v-icon>
                                </v-btn>
                            </div>

                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="10"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                :headers="tripsTableColumns"
                                :items="tripListTable"
                                :server-items-length="totalTripListTable"
                                v-model="selectedTrips"
                                :loading="isLoading"
                                @input="getTripsSelected"
                                @update:page="tripPaginationHandle"
                                :options.sync="tripOptions">
                                <template #[`item.short_name`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'TripProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.short_name }}
                                        </router-link>
                                    </div>
                                </template>

                                <template #[`item.bikes_allowed`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.bikes_allowed }} - {{ getBikesInformation(item.bikes_allowed) }}
                                    </span>
                                </template>
                                <template #[`item.wheelchair_accessible`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.wheelchair_accessible }} -
                                        {{ getWheelchairInformation(item.wheelchair_accessible) }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <v-container class="mt-4">
                        <div id="routes-list" class="mt-8">
                            <div class="d-flex">
                                <!-- search -->
                                <v-text-field
                                    expanded
                                    :placeholder="$t('Search')"
                                    outlined
                                    hide-details
                                    dense
                                    :prepend-inner-icon="icons.mdiMagnify"
                                    @change="searchStopTimes()"
                                    v-model="searchStopTimesQuery"
                                    class="user-search me-3 mb-4">
                                </v-text-field>

                                <v-btn @click.stop="getStopTimeFilter" outlined color="grey-light">
                                    <v-icon color="grey">
                                        {{ icons.mdiFilterVariant }}
                                    </v-icon>
                                </v-btn>
                            </div>

                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                :headers="stopsTableColumns"
                                :items="stopListTable"
                                :server-items-length="totalStopListTable"
                                @input="getStopTimesSelected"
                                v-model="selectedStopTimes"
                                @update:page="stopTimesPaginationHandle"
                                :options.sync="stopTimesOptions"
                                :loading="isLoading">
                                <template #[`item.departure_time`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'StopTimesProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.departure_time }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #[`item.arrival_time`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'StopTimesProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.arrival_time }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #[`item.pickup_type`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.pickup_type }} - {{ getPickUpInformation(item.pickup_type) }}
                                    </span>
                                </template>
                                <template #[`item.drop_off_type`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.drop_off_type }} -
                                        {{ getDropOffInformation(item.drop_off_type) }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <CalendarList
                        @getCalendarsSelected="getCalendarsSelected"
                        :calendarsDeletedFinished="calendarsDeletedFinished"
                        @getCalendarFilter="getCalendarFilter"
                        :fetchFilteredCalendars="fetchFilteredCalendars"
                        @getCalendarSelectedTab="getCalendarSelectedTab"
                /></v-tab-item>
            </v-tabs>
        </v-container>
    </v-sheet>
</template>

<script>
    import useTripsList from './useTripsList';
    import useStopTimesList from './useStopTimesList';
    import CalendarList from '@/views/route/calendar/CalendarList.vue';
    import { mdiFilterVariant, mdiMagnify } from '@mdi/js';
    export default {
        props: {
            tripsDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredTrips: {
                type: Boolean,
            },
            stopTimesDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredStopTimes: {
                type: Boolean,
            },
            calendarsDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredCalendars: {
                type: Boolean,
            },
        },
        setup() {
            const { tripsTableColumns, tripListTable, totalTripListTable, fetchTripList } = useTripsList();

            const { stopsTableColumns, stopListTable, totalStopListTable, fecthStopTimesList } = useStopTimesList();

            return {
                tripsTableColumns,
                tripListTable,
                totalTripListTable,
                totalStopListTable,
                stopsTableColumns,
                stopListTable,
                fetchTripList,
                fecthStopTimesList,

                icons: {
                    mdiMagnify,
                    mdiFilterVariant,
                },
            };
        },

        data() {
            return {
                selectedTrips: [],
                selectedStopTimes: [],
                activeTab: 0,
                searchTripsQuery: null,
                searchStopTimesQuery: null,
                isLoading: false,
                tripQuery: {},
                stopTimesQuery: {},
                tripOptions: {
                    page: 1,
                },
                stopTimesOptions: {
                    page: 1,
                },
            };
        },

        components: {
            CalendarList,
        },

        created() {
            this.isLoading = true;
            this.$store.commit('app-routes/resetTripsQuery');
            this.$store.commit('app-routes/resetStopTimesQuery');
            this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
            this.stopTimesQuery = this.$store.getters['app-routes/getStopTimesQuery'];
            this.fetchTripList();
            this.fecthStopTimesList();
            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        },

        methods: {
            getBikesInformation(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('NoInfoBikes');
                    case 'O1':
                        return this.$t('BikesAllowedInfo');
                    case 'O2':
                        return this.$t('BikesNotAllowedInfo');

                    default:
                        break;
                }
            },
            getWheelchairInformation(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('NoInfoWheelchair');
                    case 'O1':
                        return this.$t('WheelchairAllowedInfo');
                    case 'O2':
                        return this.$t('WheelchairNotAllowedInfo');

                    default:
                        break;
                }
            },

            getPickUpInformation(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('RegularPickup');
                    case 'O1':
                        return this.$t('NoPickupAvailable');
                    case 'O2':
                        return this.$t('PhoneAgencyPickup');
                    case 'O3':
                        return this.$t('PhoneDriverPickup');

                    default:
                        break;
                }
            },
            getDropOffInformation(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('RegularDropOff');
                    case 'O1':
                        return this.$t('NoDropOffAvailable');
                    case 'O2':
                        return this.$t('PhoneAgencyDropOff');
                    case 'O3':
                        return this.$t('PhoneDriverDropOff');

                    default:
                        break;
                }
            },

            getTripsSelected() {
                this.$emit('getTripsSelected', this.selectedTrips);
            },

            getStopTimesSelected() {
                this.$emit('getStopTimesSelected', this.selectedStopTimes);
            },

            getTripsSelectedTab() {
                this.$emit('getTripsSelectedTab', this.activeTab);
            },

            getTripFilter() {
                this.$emit('getTripFilter', true);
            },

            getStopTimeFilter() {
                this.$emit('getStopTimeFilter', true);
            },

            getCalendarsSelected(calendars) {
                this.$emit('getCalendarsSelected', calendars);
            },

            getCalendarSelectedTab(val) {
                this.$emit('getCalendarSelectedTab', val);
            },

            searchTrips() {
                this.isLoading = true;
                this.$store.commit('app-routes/setTripSearch', this.searchTripsQuery);
                this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
                this.tripQuery.pagination.page = 1;
                this.fetchTripList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            searchStopTimes() {
                this.isLoading = true;
                this.$store.commit('app-routes/setStopTimesSearch', this.searchStopTimesQuery);
                this.stopTimesQuery = this.$store.getters['app-routes/getStopTimesQuery'];
                this.stopTimesQuery.pagination.page = 1;
                this.fecthStopTimesList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            tripPaginationHandle(e) {
                if (this.tripOptions.page > this.tripQuery.pagination.page) {
                    this.tripQuery.pagination.page = this.tripOptions.page;
                    this.$store.commit('app-routes/setTripsQuery', this.tripQuery);
                    this.fetchTripList();
                }
            },

            stopTimesPaginationHandle(e) {
                if (this.stopTimesOptions.page > this.stopTimesQuery.pagination.page) {
                    this.stopTimesQuery.pagination.page = this.stopTimesOptions.page;
                    this.$store.commit('app-routes/setStopTimesQuery', this.stopTimesQuery);
                    this.fecthStopTimesList();
                }
            },

            getCalendarFilter(bol) {
                this.$emit('getCalendarFilter', bol);
            },
        },

        watch: {
            tripsDeletedFinished: function (newVal, oldVal) {
                if (newVal == true) {
                    this.fetchTripList();
                }
            },

            fetchFilteredTrips: function (newVal, oldVal) {
                if (newVal == true) {
                    this.isLoading = true;
                    this.fetchTripList();
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                }
            },

            stopTimesDeletedFinished: function (newVal, oldVal) {
                console.log(newVal);
                if (newVal == true) {
                    this.fecthStopTimesList();
                }
            },

            fetchFilteredStopTimes: function (newVal, oldVal) {
                if (newVal == true) {
                    this.isLoading = true;
                    this.fecthStopTimesList();
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
