<template>
    <v-navigation-drawer
        :value="isFiltersActive"
        style="z-index: 10"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
        app
        @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <v-card height="100%">
            <div class="drawer-header d-flex align-center mb-4">
                <span class="font-weight-semibold text-base text--primary">{{ $t('Filters') }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
                    <v-icon size="22">
                        {{ mdiClose }}
                    </v-icon>
                </v-btn>
            </div>

            <v-card-text>
                <v-btn @click="deleteFilters" expanded class="d-flex ml-auto text-capitalize" text color="error">
                    {{ $t('DeleteFiltes') }}
                    <v-icon right>
                        {{ mdiCloseBoxOutline }}
                    </v-icon>
                </v-btn>
                <div class="mt-4">
                    <v-menu
                        ref="menuArrival"
                        v-model="menuArrival"
                        :close-on-content-click="false"
                        :return-value.sync="query.filters.arrival_time.$containsi"
                        transition="scale-transition"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="query.filters.arrival_time.$containsi"
                                :label="$t('ArrivalTime')"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                filled
                                hide-details="auto"
                                name="arrivalTime"
                                shaped></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menuArrival"
                            format="24hr"
                            scrollable
                            v-model="query.filters.arrival_time.$containsi"
                            full-width
                            @click:minute="$refs.menuArrival.save(query.filters.arrival_time.$containsi)">
                        </v-time-picker>
                    </v-menu>
                    <v-menu
                        ref="menuDeparture"
                        v-model="menuDeparture"
                        :close-on-content-click="false"
                        :return-value.sync="query.filters.departure_time.$containsi"
                        transition="scale-transition"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="query.filters.departure_time.$containsi"
                                :label="$t('ArrivalTime')"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                filled
                                hide-details="auto"
                                name="arrivalTime"
                                shaped
                                class="mt-6"></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menuDeparture"
                            format="24hr"
                            scrollable
                            v-model="query.filters.departure_time.$containsi"
                            full-width
                            @click:minute="$refs.menuDeparture.save(query.filters.departure_time.$containsi)">
                        </v-time-picker>
                    </v-menu>

                    <v-autocomplete
                        hide-details="auto"
                        :search-input.sync="stopSearch"
                        @update:search-input="getStopsList"
                        v-model="query.filters.stop.name.$containsi"
                        item-value="name"
                        item-text="name"
                        :items="stops"
                        filled
                        shaped
                        name="stop"
                        class="mt-6"
                        :label="$t('Stop')">
                    </v-autocomplete>

                    <v-select
                        :items="pickupType"
                        filled
                        shaped
                        item-value="value"
                        item-text="name"
                        hide-details
                        v-model="query.filters.pickup_type.$containsi"
                        :label="$t('PickupType')"
                        class="mt-6">
                    </v-select>

                    <v-select
                        :items="dropOffType"
                        filled
                        shaped
                        item-value="value"
                        item-text="name"
                        hide-details
                        v-model="query.filters.drop_off_type.$containsi"
                        :label="$t('PickupType')"
                        class="mt-6">
                    </v-select>

                    <div class="mt-6">
                        <v-btn @click="applyFilters" color="primary" class="me-3" type="submit">
                            {{ $t('Buttons.Filter') }}
                        </v-btn>
                        <v-btn
                            @click="$emit('update:is-add-new-user-sidebar-active', false)"
                            color="secondary"
                            outlined>
                            {{ $t('Buttons.Cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import { mdiClose, mdiCloseBoxOutline } from '@mdi/js';
    import routeStoreModule from '@/views/route/routeStoreModule';
    import { getStops } from '@/api/stops';

    export default {
        data() {
            return {
                routeStoreModule,
                menuArrival: false,
                menuDeparture: false,
                query: {},
                stopSearch: '',
                stops: [],
                pickupType: [
                    { name: 'RegularPickup', value: 'O0' },
                    { name: 'NoPickupAvailable', value: 'O1' },
                    { name: 'PhoneAgencyPickup', value: 'O2' },
                    { name: 'PhoneDriverPickup', value: 'O3' },
                ],

                dropOffType: [
                    { name: 'RegularDropOff', value: 'O0' },
                    { name: 'NoDropOffAvailable', value: 'O1' },
                    { name: 'PhoneAgencyPickup', value: 'O2' },
                    { name: 'PhoneDriverPickup', value: 'O3' },
                ],
            };
        },
        model: {
            prop: 'isFiltersActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isFiltersActive: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            return {
                mdiClose,
                mdiCloseBoxOutline,
            };
        },
        created() {
            this.query = this.$store.getters['app-routes/getStopTimesQuery'];
            this.getStopsList();
            for (let index = 0; index < this.pickupType.length; index++) {
                this.pickupType[index].name = this.$t(this.pickupType[index].name);
            }

            for (let index = 0; index < this.dropOffType.length; index++) {
                this.dropOffType[index].name = this.$t(this.dropOffType[index].name);
            }
        },
        methods: {
            applyFilters() {
                this.query.pagination.page = 1;
                console.log(this.query.filters);
                this.$store.commit('app-routes/setStopTimesQuery', this.query);
                this.$emit('setQuery');
                this.$emit('update:is-add-new-user-sidebar-active', false);
            },
            deleteFilters() {
                this.query.filters.arrival_time.$containsi = '';
                this.query.filters.departure_time.$containsi = '';
                this.query.filters.stop.name.$containsi = '';
                this.query.filters.pickup_type.$containsi = '';
                this.query.filters.drop_off_type.$containsi = '';
            },

            getStopsList() {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    getStops({
                        'filters[$or]': [
                            {
                                name: {
                                    $containsi: this.stopSearch,
                                },
                            },
                        ],
                    }).then((response) => {
                        console.log(response.data.data);
                        this.stops = response.data.data;
                        this.fetchedStopName = '';
                    });
                }, 500);
            },
        },
    };
</script>
