import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const routeInitialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            type: {
                $containsi: '',
            },
            agency: {
                name: {
                    $containsi: '',
                },
            },

            $or: [
                {
                    long_name: {
                        $containsi: '',
                    },
                },
                {
                    agency: {
                        name: {
                            $containsi: '',
                        },
                    },
                },

                {
                    desc: {
                        $containsi: '',
                    },
                },
            ],
        },

        populate: ['agency'],

        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

const tripInitialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            bikes_allowed: {
                $containsi: '',
            },

            wheelchair_accessible: {
                $containsi: '',
            },

            $or: [
                {
                    short_name: {
                        $containsi: '',
                    },
                },
                {
                    route: {
                        long_name: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },

        populate: ['route'],

        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

const stopTimesInitialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            departure_time: {
                $containsi: '',
            },

            arrival_time: {
                $containsi: '',
            },

            stop: {
                name: {
                    $containsi: '',
                },
            },

            pickup_type: {
                $containsi: '',
            },

            drop_off_type: {
                $containsi: '',
            },

            $or: [
                {
                    departure_time: {
                        $containsi: '',
                    },
                },
                {
                    arrival_time: {
                        $containsi: '',
                    },
                },

                {
                    headsign: {
                        $containsi: '',
                    },
                },
                {
                    stop: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },

        populate: ['stop'],

        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

const calendarInitialQuery = () => {
    console.log(calendarInitialQuery?.filters?.end_date.$lte);
    return {
        sort: ['id:asc'],
        filters: {
            start_date: {
                /*  $gte: '0', */
            },

            end_date: {
                /*   $lte: '0', */
            },

            $or: [
                {
                    start_date: {
                        $containsi: '',
                    },
                },
                {
                    end_date: {
                        $containsi: '',
                    },
                },
            ],
        },

        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

const calendarDateInitialQuery = () => {
    return {
        sort: ['id:asc'],
        populate: ['trips'],
        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

export default {
    namespaced: true,
    state: {
        routes: [],
        routeInitialQuery: routeInitialQuery(),
        tripInitialQuery: tripInitialQuery(),
        stopTimesInitialQuery: stopTimesInitialQuery(),
        calendarInitialQuery: calendarInitialQuery(),
        calendarDateInitialQuery: calendarDateInitialQuery(),
    },

    getters: {
        getRoutesList(state) {
            return state.routes;
        },
        getRoutesQuery(state) {
            return state.routeInitialQuery;
        },
        getRoute(state) {
            return state.route;
        },

        getTripsQuery(state) {
            return state.tripInitialQuery;
        },

        getStopTimesQuery(state) {
            return state.stopTimesInitialQuery;
        },
        getCalendarsQuery(state) {
            return state.calendarInitialQuery;
        },
    },
    mutations: {
        setRoutesList(state, routes) {
            state.routes = routes;
        },
        setRoutesQuery(state, query) {
            state.routeInitialQuery = query;
        },
        resetRoutesQuery(state) {
            state.routeInitialQuery = routeInitialQuery();
        },
        setRoute(state, routeData) {
            state.routeData = routeData;
        },
        setRouteSearch(state, search) {
            state.routeInitialQuery.filters.$or[0].long_name.$containsi = search;
            state.routeInitialQuery.filters.$or[1].agency.name.$containsi = search;
            state.routeInitialQuery.filters.$or[2].desc.$containsi = search;
        },

        resetTripsQuery(state) {
            state.tripInitialQuery = tripInitialQuery();
        },

        setTripSearch(state, search) {
            state.tripInitialQuery.filters.$or[0].short_name.$containsi = search;
            state.tripInitialQuery.filters.$or[1].route.long_name.$containsi = search;
        },

        setTripsQuery(state, query) {
            state.tripInitialQuery = query;
        },

        resetStopTimesQuery(state) {
            state.stopTimesInitialQuery = stopTimesInitialQuery();
        },

        setStopTimesSearch(state, search) {
            state.stopTimesInitialQuery.filters.$or[0].departure_time.$containsi = search;
            state.stopTimesInitialQuery.filters.$or[1].arrival_time.$containsi = search;
            state.stopTimesInitialQuery.filters.$or[2].headsign.$containsi = search;
            state.stopTimesInitialQuery.filters.$or[3].stop.name.$containsi = search;
        },

        setStopTimesQuery(state, query) {
            state.stopTimesInitialQuery = query;
        },

        resetCalendarQuery(state) {
            state.calendarInitialQuery = calendarInitialQuery();
        },

        setCalendarQuery(state, query) {
            state.calendarInitialQuery = query;
        },

        resetCalendarDateQuery(state) {
            state.calendarDateInitialQuery = calendarDateInitialQuery();
        },

        setCalendarDateQuery(state, query) {
            state.calendarDateInitialQuery = query;
        },

        setCalendarSearch(state, search) {
            state.calendarInitialQuery.filters.$or[0].start_date.$containsi = search;
            state.calendarInitialQuery.filters.$or[1].end_date.$containsi = search;
        },
    },

    actions: {
        fetchRouteList(ctx) {
            let _query = _.cloneDeep(ctx.state.routeInitialQuery);
            /*  delete _query.filters.country.id.$containsi;
            if (!_query.filters.district.id.$containsi) {
                delete _query.filters.district;
            }
            if (!_query.filters.county.id.$containsi) {
                delete _query.filters.county;
            }
            if (!_query.filters.parish.id.$containsi) {
                delete _query.filters.parish;
            }
            if (!_query.filters.managers.id.$containsi) {
                delete _query.filters.managers;
            }
            if (!_query.filters.occupationPercentage.$between) {
                delete _query.filters.occupationPercentage;
            } */
            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-routes?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchRoute(ctx, id, query = {}) {
            query.populate = ['agency'];
            return new Promise((resolve, reject) => {
                axios
                    .get(`/mobility-routes/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        addRoute(ctx, routeData) {
            routeData = _.assign({}, routeData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/mobility-routes', { data: routeData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        updateRoute(ctx, data) {
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;

                axios
                    .put(`/mobility-routes/${id}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteRoute(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-routes/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteManyRoutes(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-routes/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        //trips

        fetchTripList(ctx) {
            let _query = _.cloneDeep(ctx.state.tripInitialQuery);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-trips?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        fetchTrip(ctx, id, query = {}) {
            query.populate = ['transport', 'route'];
            return new Promise((resolve, reject) => {
                axios
                    .get(`/mobility-trips/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        updateTrip(ctx, data) {
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;

                axios
                    .put(`/mobility-trips/${id}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        addTrip(ctx, tripData) {
            tripData = _.assign({}, tripData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/mobility-trips', { data: tripData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteTrip(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-trips/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteManyTrips(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-trips/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        //stopTimes

        fetchStopTimesList(ctx) {
            let _query = _.cloneDeep(ctx.state.stopTimesInitialQuery);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-stop-times?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        fetchStopTime(ctx, id, query = {}) {
            query.populate = ['trip', 'stop'];
            return new Promise((resolve, reject) => {
                axios
                    .get(`/mobility-stop-times/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        addStopTime(ctx, stopData) {
            stopData = _.assign({}, stopData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/mobility-stop-times', { data: stopData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        updateStopTime(ctx, data) {
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                axios
                    .put(`/mobility-stop-times/${id}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteStopTime(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-stop-times/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteManyStopTimes(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-stop-times/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        //calendar

        fetchCalendarList(ctx) {
            let _query = _.cloneDeep(ctx.state.calendarInitialQuery);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-calendars?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        fetchCalendar(ctx, id, query = {}) {
            query.populate = ['trips'];
            return new Promise((resolve, reject) => {
                axios
                    .get(`/mobility-calendars/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        addCalendar(ctx, calendarData) {
            calendarData = _.assign({}, calendarData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/mobility-calendars', { data: calendarData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        updateCalendar(ctx, data) {
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                axios
                    .put(`/mobility-calendars/${id}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteCalendar(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-calendars/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteManyCalendars(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-calendars/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        //calendar date

        fetchCalendarDateList(ctx) {
            let _query = _.cloneDeep(ctx.state.calendarDateInitialQuery);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-calendar-dates?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
