<template>
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
        <v-container>
            <v-tabs center-active v-model="activeTab" @change="getCalendarSelectedTab">
                <v-tab>{{ $t('Calendar') }}</v-tab>
                <v-tab>{{ $t('CalendarException') }}</v-tab>

                <v-tab-item>
                    <v-container class="mt-4">
                        <div id="routes-list" class="mt-8">
                            <div class="d-flex">
                                <!-- search -->
                                <v-text-field
                                    expanded
                                    :placeholder="$t('Search')"
                                    outlined
                                    hide-details
                                    dense
                                    :prepend-inner-icon="icons.mdiMagnify"
                                    class="user-search me-3 mb-4"
                                    @change="searchCalendars()"
                                    v-model="searchCalendarsQuery">
                                </v-text-field>

                                <v-btn @click.stop="getCalendarFilter" outlined color="grey-light">
                                    <v-icon color="grey">
                                        {{ icons.mdiFilterVariant }}
                                    </v-icon>
                                </v-btn>
                            </div>

                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                :headers="calendarTableColumns"
                                :items="calendarListTable"
                                :server-items-length="totalCalendarListTable"
                                :options.sync="calendarOptions"
                                @update:page="calendarPaginationHandle"
                                @input="getCalendarsSelected"
                                v-model="selectedCalendars"
                                :loading="isLoading">
                                <template #[`item.start_date`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'CalendarProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.start_date }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #[`item.end_date`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'CalendarProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.end_date }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #[`item.monday`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.monday }} - {{ getCalendarAvailability(item.monday) }}
                                        {{ $tc('Monday', 2) }}
                                    </span>
                                </template>
                                <template #[`item.tuesday`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.tuesday }} - {{ getCalendarAvailability(item.tuesday) }}
                                        {{ $tc('Tuesday', 2) }}
                                    </span>
                                </template>
                                <template #[`item.wednesday`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.wednesday }} - {{ getCalendarAvailability(item.wednesday) }}
                                        {{ $tc('Wednesday', 2) }}
                                    </span>
                                </template>
                                <template #[`item.thursday`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.monday }} - {{ getCalendarAvailability(item.thursday) }}
                                        {{ $tc('Thursday', 2) }}
                                    </span>
                                </template>
                                <template #[`item.friday`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.friday }} - {{ getCalendarAvailability(item.friday) }}
                                        {{ $tc('Friday', 2) }}
                                    </span>
                                </template>
                                <template #[`item.saturday`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.saturday }} - {{ getCalendarAvailability(item.saturday) }}
                                        {{ $tc('Saturday', 2) }}
                                    </span>
                                </template>
                                <template #[`item.sunday`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.sunday }} - {{ getCalendarAvailability(item.sunday) }}
                                        {{ $tc('Sunday', 2) }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <v-container class="mt-4">
                        <div id="routes-list" class="mt-8">
                            <div class="d-flex">
                                <!-- search -->
                                <v-text-field
                                    expanded
                                    :placeholder="$t('Search')"
                                    outlined
                                    hide-details
                                    dense
                                    :prepend-inner-icon="icons.mdiMagnify"
                                    class="user-search me-3 mb-4">
                                </v-text-field>

                                <v-btn @click.stop="isFiltersActive = !isFiltersActive" outlined color="grey-light">
                                    <v-icon color="grey">
                                        {{ icons.mdiFilterVariant }}
                                    </v-icon>
                                </v-btn>
                            </div>

                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                :headers="calendarDateTableColumns"
                                :items="calendarDateListTable"
                                :server-items-length="totalCalendarDateListTable"
                                :options.sync="calendarDateOptions"
                                @update:page="calendarDatePaginationHandle">
                                <template #[`item.exception_type`]="{ item }">
                                    <span class="font-weight-medium text--primary">
                                        {{ item.exception_type }} -
                                        {{ getCalendarDatesAvailability(item.exception_type) }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-tab-item>
            </v-tabs>
        </v-container>
    </v-sheet>
</template>

<script>
    import useCalendarList from './useCalendarList';
    import useCalendarDateList from './useCalendarDateList';
    import { mdiFilterVariant, mdiMagnify } from '@mdi/js';
    export default {
        props: {
            calendarsDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredCalendars: {
                type: Boolean,
            },
        },
        setup() {
            const { calendarListTable, calendarTableColumns, totalCalendarListTable, fetchCalendarList } =
                useCalendarList();

            const {
                calendarDateListTable,
                calendarDateTableColumns,
                totalCalendarDateListTable,
                fetchCalendarDateList,
            } = useCalendarDateList();

            return {
                calendarListTable,
                calendarTableColumns,
                totalCalendarListTable,
                fetchCalendarList,

                calendarDateListTable,
                calendarDateTableColumns,
                totalCalendarDateListTable,
                fetchCalendarDateList,

                icons: {
                    mdiMagnify,
                    mdiFilterVariant,
                },
            };
        },

        data() {
            return {
                activeTab: 0,
                searchCalendarsQuery: null,
                isLoading: false,
                calendarQuery: {},
                calendarDateQuery: {},
                calendarOptions: {
                    page: 1,
                },
                calendarDateOptions: {
                    page: 1,
                },
                selectedCalendars: [],
            };
        },
        methods: {
            getCalendarAvailability(availability) {
                if (availability == 'O1') {
                    return this.$t('calendarAvailable');
                } else return this.$t('calendarNotAvailable');
            },

            getCalendarDatesAvailability(availability) {
                if (availability == 'O1') {
                    return this.$t('calendarDatesAvailable');
                } else return this.$t('calendarDatesNotAvailable');
            },

            calendarPaginationHandle(e) {
                if (this.calendarOptions.page > this.calendarQuery.pagination.page) {
                    this.calendarQuery.pagination.page = this.calendarOptions.page;
                    this.$store.commit('app-routes/setCalendarQuery', this.calendarQuery);
                    this.fetchCalendarList();
                }
            },

            calendarDatePaginationHandle(e) {
                if (this.calendarDateOptions.page > this.calendarDateQuery.pagination.page) {
                    this.calendarDateQuery.pagination.page = this.calendarDateOptions.page;
                    this.$store.commit('app-routes/setCalendarDateQuery', this.calendarDateQuery);
                    this.fetchCalendarDateList();
                }
            },

            getCalendarsSelected() {
                this.$emit('getCalendarsSelected', this.selectedCalendars);
            },

            searchCalendars() {
                this.isLoading = true;
                this.$store.commit('app-routes/setCalendarSearch', this.searchCalendarsQuery);
                this.calendarQuery = this.$store.getters['app-routes/getCalendarsQuery'];
                this.calendarQuery.pagination.page = 1;
                this.fetchCalendarList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            getCalendarFilter() {
                this.$emit('getCalendarFilter', true);
            },

            getCalendarSelectedTab() {
                this.$emit('getCalendarSelectedTab', this.activeTab);
            },
        },

        watch: {
            calendarsDeletedFinished: function (newVal, oldVal) {
                console.log(newVal);
                if (newVal == true) {
                    this.fetchCalendarList();
                }
            },

            fetchFilteredCalendars: function (newVal, oldVal) {
                console.log(newVal);
                if (newVal == true) {
                    this.isLoading = true;
                    this.fetchCalendarList();
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                }
            },
        },

        created() {
            this.isLoading = true;
            this.$store.commit('app-routes/resetTripsQuery');
            this.$store.commit('app-routes/resetStopTimesQuery');
            this.calendarQuery = this.$store.getters['app-routes/resetCalendarQuery'];
            this.calendarDateQuery = this.$store.getters['app-routes/resetCalendarDateQuery'];
            this.fetchCalendarList();
            this.fetchCalendarDateList();

            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
